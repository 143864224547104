import { HMSPrebuilt, HMSPrebuiltRefType } from '@100mslive/roomkit-react';
import { memo, useEffect, useRef, useState } from 'react';
import SpeakerIcon from '../assets/speaker.png';
import { HMSLogLevel } from '@100mslive/react-sdk';

interface LiveBoxProps {
	roomCode: string;
	studentDetails: {
		user_id: number;
		name: string;
		class_code: string;
	};
}

const LiveBox = ({ roomCode, studentDetails }: LiveBoxProps) => {
	const [isMuted, setIsMuted] = useState(true);
	const ref = useRef<HMSPrebuiltRefType>(null);

	const handleJoinRoom = async () => {
		if (!ref?.current?.hmsActions) return;
		const hmsActions = ref?.current?.hmsActions;
		hmsActions?.setLogLevel(HMSLogLevel.ERROR);
		if (isMuted) {
			try {
				await hmsActions?.setAudioSettings({
					volume: 0,
				});
			} catch (error) {
				console.log('error', error);
			}
			// try {
			// 	await hmsActions?.setRemoteTracksEnabled({ type: 'audio', enabled: false });
			// } catch (error) {
			// 	console.log('error', error);
			// }
			try {
				await hmsActions?.setVolume(0);
			} catch (error) {
				console.log('error', error);
			}
		} else {
			try {
				await hmsActions?.setAudioSettings({
					volume: 100,
				});
			} catch (error) {
				console.log('error', error);
			}
			// try {
			// 	await hmsActions?.setRemoteTracksEnabled({ type: 'audio', enabled: true });
			// } catch (error) {
			// 	console.log('error', error);
			// }
			try {
				await hmsActions?.setVolume(100);
			} catch (error) {
				console.log('error', error);
			}
		}
	};

	useEffect(() => {
		if (ref?.current) {
			handleJoinRoom();
		}
	}, [isMuted, ref?.current]);

	const handleMute = async () => {
		setIsMuted((muted) => !muted);
	};

	const renderMuteIcon = () => {
		return (
			<div
				onClick={handleMute}
				style={{
					position: 'absolute',
					right: 0,
					top: 0,
					borderRadius: '100%',
					zIndex: 999999,
					width: '40px',
					height: '40px',
					cursor: 'pointer',
					backgroundColor: 'white',
				}}
			>
				{isMuted && (
					<div
						style={{
							top: 0,
							right: '50%',
							position: 'absolute',
							width: '2px',
							height: '40px',
							backgroundColor: 'white',
							rotate: '135deg',
						}}
					></div>
				)}
				<img
					style={{
						width: '40px',
						height: '40px',
						cursor: 'pointer',
					}}
					src={SpeakerIcon}
					alt="speaker"
				/>
			</div>
		);
	};

	return (
		<div style={{ height: '50vh', width: '100%', position: 'relative' }}>
			{renderMuteIcon()}
			<HMSPrebuilt
				ref={ref}
				options={{ userName: 'panel-user', userId: 'panel-user' }}
				screens={{
					preview: { skip_preview_screen: true },
					conferencing: {
						default: {
							elements: {
								chat: {
									allow_pinning_messages: false,
									public_chat_enabled: false,
									private_chat_enabled: false,
									real_time_controls: {
										can_disable_chat: false,
										can_block_user: false,
										can_hide_message: false,
									},
								},
								brb: false,
								emoji_reactions: false,
								on_stage_exp: {},
								hand_raise: false,
								noise_cancellation: { enabled_by_default: true },
							},
						},
					},
				}}
				onJoin={handleJoinRoom}
				roomCode={roomCode}
			/>
			<div
				style={{
					fontSize: '14px',
					fontWeight: 'bold',
					color: 'white',
					width: '100%',
					backgroundColor: 'grey',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<p>Name: {studentDetails.name}</p>
				<p>
					Class: {studentDetails.class_code}, User Id: {studentDetails.user_id}
				</p>
			</div>
		</div>
	);
};

export default memo(LiveBox);
