import LiveBox from '@components/LiveBox';
import useFetchRooms from '@hooks/useFetchRooms';

const LiveMonitoring = () => {
	const rooms = useFetchRooms();
	// const rooms = [
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// 	{ alias: 'vki-weat-xzr' },
	// ];
	if (!rooms.length) return <div>No rooms found</div>;
	const roomAliases = rooms?.map((room) => room?.alias).join('_');

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<div
				key={roomAliases}
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(4, 1fr)',
					gap: '20px',
					height: '100%',
					width: '100%',
				}}
			>
				{rooms.map((room, idx) => (
					<LiveBox studentDetails={room.student_detail} key={idx} roomCode={room.alias} />
				))}
			</div>
		</div>
	);
};

export default LiveMonitoring;
