import ToastHelper from '@helpers/toast_helper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { LAMBDA_URL } from '../../constants';
import '../../styles/main.css';

const BulkUploader = () => {
	const [payload, setPayload] = useState<any>();
	const [requestId, setRequestID] = useState();
	const [targetSource, setTargetSource] = useState('');
	const [errorLength, setErrorLength] = useState();
	const [loader, setLoader] = useState(false);

	function getStatusCheckUrl(targetSource: string, reqId: string) {
		if (!targetSource) return null;
		return `https://${targetSource}.ariseall.com/api/operations/bulk-status?request_id=${reqId}`;
	}

	function getObject(value: any) {
		switch (value) {
			case 'chat_content':
				return {
					flow_name: 'fetch-chat-q',
					sheetName: 'chat_contents',
					targetSource: 'content',
				};
			case 'module_question':
				return {
					flow_name: 'fetch-module-entities',
					sheetName: 'module_entities',
					targetSource: 'content',
				};
			case 'full_module_question':
				return {
					flow_name: 'fetch-full-module-entities',
					sheetName: 'module_entities',
					targetSource: 'content',
				};
			case 'invite':
				return {
					flow_name: 'fetch-invitations',
					sheetName: 'invitations',
					targetSource: 'api',
				};
			case 'lead':
				return {
					flow_name: 'fetch-leads',
					sheetName: 'leads',
					targetSource: 'api',
				};
			case 'module':
				return {
					flow_name: 'fetch-create-modules',
					sheetName: 'modules',
					targetSource: 'content',
				};
			case 'full_module':
				return {
					flow_name: 'fetch-full-modules',
					sheetName: 'modules',
					targetSource: 'content',
				};
			case 'ques_content':
				return {
					flow_name: 'fetch-create-questions',
					sheetName: 'questions',
					targetSource: 'content',
				};
			case 'payment_upload':
				return {
					flow_name: 'fetch-user-payment',
					sheetName: 'bulk_payments',
					targetSource: 'api',
				};
			case 'bulk_create_questions':
				return {
					flow_name: 'fetch-bulk-create-questions',
					sheetName: 'bulk_questions',
					targetSource: 'content',
				};
			case 'bulk_book_upload':
				return {
					flow_name: 'upload-book',
					sheetName: 'book_upload',
					targetSource: 'content',
				};
			case 'bulk_book_series_upload':
				return {
					flow_name: 'upload-book-series',
					sheetName: 'book_series',
					targetSource: 'content',
				};
			case 'bulk_book_series_modules_upload':
				return {
					flow_name: 'upload-book-series-modules',
					sheetName: 'book_series_modules',
					targetSource: 'content',
				};
			default:
				return null;
		}
	}

	useEffect(() => {
		if (requestId) {
			const res_url = getStatusCheckUrl(targetSource, requestId);
			if (!res_url) return;
			axios
				.get(res_url)
				.then(function (res) {
					setLoader(false);
					new ToastHelper().showInfo(`record count ${res?.data?.data?.record_count}`);
					new ToastHelper().showSuccess(`success count ${res?.data?.data?.success_count}`);
					new ToastHelper().showError(`failed count ${res?.data?.data?.failed_count}`);
					setErrorLength(res?.data?.data?.failed_count);
				})
				.catch(() => {
					setLoader(false);
					new ToastHelper().showError('Oops! Error in uploading. Please try again after sometime.');
				});
		}
	}, [targetSource, requestId]);

	const uploadSheetData = (payload: any) => {
		if (payload !== null) {
			setLoader(true);
			axios
				.post(LAMBDA_URL, {
					...payload,
				})
				.then((res) => {
					setTargetSource(payload.targetSource);
					setRequestID(res?.data?.data?.request_id);
				})
				.catch(() => {
					new ToastHelper().showError('Oops! Error in uploading. Please try again after sometime.');
				});
		}
	};

	function handleSelectChange(event: any) {
		const selectedValue = event.target.value;
		const selected = getObject(selectedValue);
		setPayload(selected);
	}
	const error_url = `/bulk-operation-report-${
		targetSource === 'api' ? 'main' : 'content'
	}?pageSize=20&current=1&sorter[0][field]=id&sorter[0][order]=desc&filters[0][field]=bulk_request_id&filters[0][value]=${requestId}&filters[0][operator]=contains`;

	return (
		<div className="bulk_upload">
			<select onChange={handleSelectChange}>
				<option value="">Select an object</option>
				<option value="ques_content">Questions Upload</option>
				<option value="module_question">Module Question Mapping</option>
				<option value="full_module_question">Full Module Question Mapping</option>
				<option value="invite">Invitations Upload</option>
				<option value="lead">Leads Upload</option>
				<option value="module">Modules Upload</option>
				<option value="full_module">Full Modules Upload</option>
				<option value="chat_content">Chat Content Upload</option>
				<option value="payment_upload">Payment Upload</option>
				<option value="bulk_create_questions">Bulk Questions Upload</option>
				<option value="bulk_book_upload">Bulk Book Upload</option>
				<option value="bulk_book_series_upload">Bulk Book Series Upload</option>
				<option value="bulk_book_series_modules_upload">Bulk Book Series Modules Upload</option>
			</select>
			<button className="bulk_upload_submit_btn" onClick={() => uploadSheetData(payload)}>
				Submit
			</button>
			{errorLength ? (
				<div className="error_link">
					<a href={error_url}>Click On This Link To View Errors</a>
				</div>
			) : null}
			{loader && <div className="loading">Loading...</div>}
		</div>
	);
};

export default BulkUploader;
